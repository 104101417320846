/* Dashboard Css Start Here */
.dashboard_sec {
    padding: 60px 0;
}

.dashboard_sec .title {
    padding: 0 0 30px 0;
    text-align: center;
}

.dashboard_sec .welcome_heading h3 {
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 10px;
}

.dashboard_sec .user_box .image_uploader label {
    height: 310px;
    /* margin-bottom: 30px; */
}

.dashboard_sec .user_box .image_uploader .upload label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ddd;
    border-radius: 10px;
    font-size: 25px;
    font-family: 'Rubik';
    color: #000;
    background: #49a8c90d;
    flex-direction: column;
}

.dashboard_sec .user_box .image_uploader .upload label p {
    color: #a7a7a7;
    font-size: 14px;
    font-family: 'Poppins';
    margin-top: 2px;
}

.dashboard_sec .user_box .image_uploader .upload label i {
    margin-left: 10px;
    color: #17723e;
}

.dashboard_sec .user_box .nofile {
    display: none;
}

.dashboard_sec .user_box .preview .title {
    text-align: left;
}

.dashboard_sec .user_box .preview .title h3 {
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: 500;
    margin: 0;
    line-height: 35px;
}

.dashboard_sec .user_box .preview .img-preview img {
    height: 300px;
    object-fit: unset;
    object-position: top;
    border-radius: 5px;
    margin-bottom: 10px;
}

.dashboard_sec .user_box .form-group .form-control {
    border: 1px solid #ddd;
    height: 50px;
    font-size: 14px;
}

.dashboard_sec .user_box .form-group .dropdown-heading {
    padding: 24px 12px;
    color: #6c757d;
    font-size: 14px;
}

.dashboard_sec .user_box .form-group textarea {
    height: 110px !important;
    resize: none;
}

.dashboard_sec .user_box .button-group button {
    width: 100%;
    padding: 12px 20px;
    border: 0;
    background: #17723e;
    color: #fff;
    border-radius: 5px;
}

.dashboard_sec .user_box .no_preview img {
    border-radius: 10px;
    height: 310px;
    width: 100%;
}

.dashboard_sec .user_box .post-title {
    padding-top: 21px;
}

.dashboard_sec .user_box .post-title h4 {
    font-size: 27px;
    line-height: 37px;
}

.dashboard_sec .user_box .form-group .rti--container {
    padding: 12px 15px;
    border-radius: 5px;
    border-color: #ddd;
}

.dashboard_sec .user_box .form-group .rti--container {
    padding: 12px 15px;
    border-radius: 5px;
    border-color: #ddd;
}

.dashboard_sec .user_box .post-location {
    margin-bottom: 10px;
}

.dashboard_sec .user_box .post-location h4 {
    color: #000;
}

.dashboard_sec .user_box .post-description p {
    font-size: 14px;
    color: #646464;
}

.dashboard_sec .user_box ul.tags {
    padding: 0;
    list-style: none;
    margin: 0;
}

.dashboard_sec .user_box ul.tags li {
    display: inline-block;
    background: #17723e2b;
    margin: 0 6px 0 0;
    padding: 5px 13px;
    border-radius: 3px;
    font-size: 14px;
    text-transform: capitalize;
}

/* Admin Section Css Start Here */
.dashboard_sec .dashboard_sidebar {
    border: 1px solid #ddd;
    padding: 30px 0 10px;
    background: #49a8c90d;
}

.dashboard_sec .dashboard_sidebar .profile-header {
    padding: 0px 20px;
    text-align: center;
}

.dashboard_sec .dashboard_sidebar .profile-img img {
    border-radius: 50px;
}

.dashboard_sec .dashboard_sidebar .profile-img {
    max-width: 120px;
    margin: 0px auto;
}

.dashboard_sec .dashboard_sidebar .name {
    padding: 12px 0;
}

.dashboard_sec .dashboard_sidebar .name h4 {
    font-size: 25px;
    font-weight: 500;
}

.dashboard_sec .dashboard_sidebar .name p {
    font-size: 15px;
    line-height: 20px;
}

.dashboard_sec .dashboard_sidebar ul.menus {
    padding: 0;
    margin: 0;
    list-style: none;
}

.dashboard_sec .dashboard_sidebar ul.menus li {
    border-top: 1px solid #ddd;
    padding: 8px 13px;
}

.dashboard_sec .dashboard_sidebar ul.menus li a {
    color: #000;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    text-decoration: none;
    position: relative;
}

.dashboard_sec .dashboard_sidebar ul.menus li a span.noti {
    background: red;
    color: #fff;
    font-size: 8px;
    width: 14px;
    position: absolute;
    height: 14px;
    border-radius: 50%;
    top: 4px;
    right: -22px;
    text-align: center;
    line-height: 16px;
}

.dashboard_sec .module_title {
    text-align: left;
    padding-bottom: 15px;
}

.dashboard_sec .module_title h3 {
    font-size: 27px;
    font-weight: 400;
}

.dashboard_sec .approval_box thead {
    background: #edfff8;
}

.dashboard_sec .approval_box thead th:first-child {
    text-align: left;
}

.dashboard_sec .approval_box thead th {
    border: 0;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
}

.dashboard_sec .approval_box td:first-child {
    text-align: left;
}

.dashboard_sec .approval_box td {
    border: 0;
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
}

.dashboard_sec .approval_box td ul.all-tags {
    padding: 0;
    margin: 0;
    list-style: none;
}

.dashboard_sec .approval_box td ul.all-tags li {
    display: inline-block;
    margin: 0 3px;
}

.dashboard_sec .approval_box td ul.all-tags li span {
    background: #49a8c921;
    padding: 0 10px;
    margin: 2px 0;
    border-radius: 3px;
    font-size: 11px;
}

.dashboard_sec .approval_box thead th:first-child {
    text-align: left;
}

.dashboard_sec .approval_box td:first-child {
    text-align: left;
}

.dashboard_sec .approval_box td.apporval button {
    margin: 0 3px;
    border: 0;
    font-size: 13px;
    width: 27px;
    height: 27px;
    border-radius: 3px;
    color: #fff;
}

.dashboard_sec .approval_box td.apporval button.approved {
    color: #fff;
    background: green;
}

.dashboard_sec .approval_box td.apporval button.denied {
    background: red;
}

.dashboard_sec .approval_box tbody tr {
    border-bottom: 1px solid #ddd;
}

.dashboard_sec .approval_box .title {
    text-align: left;
    padding-bottom: 15px;
}

.dashboard_sec .approval_box .title h3 {
    font-size: 27px;
}

.dashboard_sec .recently_added thead {
    background: #edfff8;
}

.dashboard_sec .recently_added thead tr th {
    border: 0;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
}

.dashboard_sec .recently_added tbody td {
    border: 0;
}

.dashboard_sec .recently_added tbody td {
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    padding: 15px 15px;
}

.dashboard_sec .recently_added tbody td:first-child {
    text-align: left;
}

.dashboard_sec .recently_added thead th:first-child {
    text-align: left;
}

.dashboard_sec .recently_added label.recently_container {
    margin: 0;
}

.dashboard_sec .recently_added tbody td .form-control {
    box-shadow: none;
    width: 20px;
    margin: 0px auto;
    height: 20px;
}

.dashboard_sec .recently_added tbody tr {
    border-bottom: 1px solid #ddd;
}

.dashboard_sec .recently_added .apporval .button-group {
    display: flex;
}

.dashboard_sec .recently_added tbody td:last-child .button-group {
    justify-content: center;
}

.dashboard_sec .recently_added .apporval .button-group button {
    margin: 0 3px;
    border: 0;
    font-size: 13px;
    width: 27px;
    height: 27px;
    border-radius: 3px;
}

.dashboard_sec .recently_added .apporval .button-group button.approved {
    color: #fff;
    background: green;
}

.dashboard_sec .recently_added .apporval .button-group button.denied {
    background: red;
    color: #ffff;
}

/* Admin Sec Css End Here */

/* Owner Sec Css Start Here */
.add_admin {
    padding: 60px 0;
}

.add_admin .add-admin .title {
    padding-bottom: 15px;
}

.add_admin .add-admin form {
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px #ccc;
    padding: 30px 40px;
}

.add_admin .add-admin form label {
    font-size: 15px;
    font-family: 'Rubik';
    font-weight: 500;
}

.add_admin .add-admin form .form-control {
    border-radius: 0;
    height: 45px;
    color: #7a7a7a;
    font-size: 15px;
    box-shadow: none;
}

.add_admin .add-admin form .button-group .btn {
    background: #17723e;
    border-radius: 0;
    color: #fff;
    width: 100%;
    padding: 13px 10px;
    margin-top: 10px;
    transition: 0.6s;
    border: 2px solid #17723e;
}

.add_admin .add-admin form .button-group .btn:hover {
    background: transparent;
    color: #17723e;
}

.add_admin .all-admins .title {
    padding-bottom: 18px;
}


.add_admin .all-admins table thead {
    background: #edfff8;
}

.add_admin .all-admins table thead th {
    border: 0;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
}

.add_admin .all-admins table thead th:first-child {
    text-align: left;
}

.add_admin .all-admins table tbody td {
    border: 0;
    font-size: 13px;
    text-align: center;
}

.add_admin .all-admins table tbody td:first-child {
    text-align: left;
}

.add_admin .all-admins table tbody button.remove {
    background: #db0101;
    border: 0;
    box-shadow: none;
    outline: none;
    color: #fff;
    font-size: 13px;
    border-radius: 3px;
    padding: 7px 7px;
}

.add_admin .all-admins table tbody tr {
    border-bottom: 1px solid #ddd;
}

.add_admin .all-admins table {
    box-shadow: 0px 0px 12px #ccc;
    border: 1px solid #eee;
}

.add_admin .all-admins .bottom-bar button {
    background: #17723e;
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;
    font-size: 15px;
    border: 0;
}

.add_admin .all-admins .button-group button {
    background: #17723e;
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;
    font-size: 15px;
    border: 0;
}
.add_admin .all-admins .button-group a {
    background: #17723e;
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;
    font-size: 15px;
    border: 0;
}

.add_admin .all-admins .bottom-bar {
    text-align: end;
    background: #fff;
    box-shadow: 0px 0px 10px #ccc;
    padding: 20px 20px;
}

.add_admin .all-admins .bottom-bar .total {
    width: 100%;
    margin: 0px 0px 0px auto;
}

.add_admin .all-admins .bottom-bar .total h4 {
    font-size: 16px;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
}

.add_admin .all-admins .bottom-bar .button-group {
    padding-top: 15px;
}

.add_admin .all-admins table tbody tr:last-child {
    border-bottom: 0;
}

.add_admin .all-admins .bottom-bar .total h4 img {
    width: 13px;
}

.add_admin .all-admins .bottom-bar .total h4 img {
    width: 13px;
    margin-left: 10px;
}

/* Owner Sec Css End Here */
@media (max-width: 575px) {
    .dashboard_sec {
        padding: 40px 0;
    }

    .dashboard_sec .title {
        padding: 0 0 30px 0;
    }

    .dashboard_sec .title h2 {
        font-size: 27px;
        line-height: 37px;
    }

    .dashboard_sec .welcome_heading h3 {
        font-size: 19px;
        line-height: 29px;
    }

    .dashboard_sec .title h2 {
        font-size: 27px;
        line-height: 37px;
    }

    .dashboard_sec .user_box .preview {
        margin-top: 40px;
    }

    .dashboard_sec .user_box .preview .title h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .dashboard_sec .user_box .post-title h4 {
        font-size: 23px;
        line-height: 33px;
    }

    .dashboard_sec .user_box ul.tags li {
        margin-bottom: 5px;
    }

    .dashboard_sec .user_box .post-description p {
        word-break: break-all;
    }

    .dashboard_sec .dashboard_sidebar {
        margin-bottom: 40px;
    }

    .dashboard_sec .approval_box td.apporval .button-group {
        display: flex;
    }

    .dashboard_sec .recently_added td {
        min-width: 150px;
    }

    .add_admin .add-admin form {
        margin-bottom: 30px;
    }

    .add_admin .add-admin form {
        padding: 20px 15px;
    }

    .add_admin .all-admins .bottom-bar {
        margin-top: 20px;
    }
}

/* Dashboard Css End Here */