/* Faqs Css Start Here */
.faqs_sec {
    padding: 70px 0;
}

.faqs_sec .card {
    margin-bottom: 10px;
    background: transparent;
    border: 0;
}

.faqs_sec .card .card-body {
    padding: 15px 15px;
    border: 1px solid #ddd;
    font-size: 15px;
    border-top: 0;
}

.faqs_sec .card .card-header {
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
}

.faqs_sec .card .card-header button {
    box-shadow: none;
    outline: none;
    background: #f5f5f5;
    border-radius: 0;
    padding: 15px 15px 15px 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    text-decoration: none;
    position: relative;
}

.faqs_sec .card .card-header button:before {
    content: '';
    background: #f5f5f5;
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0px;
}

.faqs_sec .card .card-header button h5 {
    margin: 0;
    font-size: 15px;
}

.faqs_sec .card .card-header button.btn[aria-expanded="true"] i:before {
    content: '\f068 ';
}

@media (max-width: 575px) {
    .faqs_sec {
        padding: 40px 0;
    }

    .faqs_sec .card .card-header button {
        padding: 14px 15px 15px 10px;
        text-align: left;
        font-size: 13px;
    }

    .faqs_sec .card .card-body {
        font-size: 13px;
    }

    .faqs_sec .card .card-header button h5 {
        font-size: 13px;
    }
}

/* Faqs Css End Here */