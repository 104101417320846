/* Chat Css Start Here */
.chat_sec {
    padding: 160px 0 60px;
}

.chat_sec .row {
    border: 1px solid #dddddd5e;
    border-radius: 10px;
}

.chat_sec .chat-list {
    border-right: 1px solid #ddd;
    /* padding: 20px 0px 0; */
}

.chat_sec .chat-list .title {
    padding: 0 20px 5px;
}

.chat_sec .chat-list ul.all-chats {
    padding: 0;
    margin: 0;
    list-style: none;
    height: 720px;
    overflow: scroll;
}

.chat_sec .chat-list ul.all-chats::-webkit-scrollbar {
    width: 0;
}

.chat_sec .chat-list ul.all-chats .chat {
    display: flex;
    align-items: flex-start;
}

.chat_sec .chat-list ul.all-chats .chat figure {
    margin: 0;
}

.chat_sec .chat-list ul.all-chats .chat .name h4 {
    font-size: 15px;
    line-height: 15px;
}

.chat_sec .chat-list ul.all-chats .chat .name h5 {
    font-size: 13px;
}

.chat_sec .chat-list ul.all-chats .chat .name h5.online {
    font-size: 10px;
    padding-left: 15px;
    position: relative;
    margin: 0;
}

.chat_sec .chat-list ul.all-chats .chat {
    border-bottom: 1px solid #dddddd5e;
    padding: 15px 20px;
}

.chat_sec .chat-list ul.all-chats .chat .name {
    padding-left: 10px;
    position: relative;
}

.chat_sec .chat-list ul.all-chats .chat .name h5.online span.dot {
    background: #3bd307;
    width: 7px;
    height: 7px;
    position: absolute;
    left: 1px;
    border-radius: 50%;
    top: 8px;
}

.chat_sec .chat-list ul.all-chats li:last-child .chat {
    border-bottom: 0;
}

.chat_sec .chat-list ul.all-chats .actve-chat {
    background: #267b4a38;
}

.chat_sec .chat-list ul.all-chats .chat .name h5.offline {
    font-size: 10px;
    color: red;
    padding-left: 15px;
    position: relative;
}

.chat_sec .chat-list ul.all-chats .chat .name h5.offline .dot {
    background: red;
    width: 7px;
    height: 7px;
    position: absolute;
    left: 1px;
    border-radius: 50%;
    top: 8px;
}

.the-chats .chat-user {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background: linear-gradient(to right,
            #fe5b25,
            #fe6c1e,
            #fd7c18,
            #fb8b14,
            #f99914);
    padding: 10px 15px;
}

.the-chats .chat-user .name h4 {
    font-size: 15px;
    line-height: 15px;
}

.the-chats .chat-user .name h5 {
    font-size: 10px;
    color: #000;
    padding-left: 15px;
    position: relative;
}

.the-chats .chat-user .name h5 span.dot {
    background: #3bd307;
    width: 7px;
    height: 7px;
    position: absolute;
    left: 1px;
    border-radius: 50%;
    top: 7px;
}

.the-chats .chat-user .name {
    margin-left: 10px;
}

.the-chats .chat-user figure {
    margin: 0;
}

.the-chats {
    height: 100%;
    position: relative;
    padding-bottom: 100px;
    background: transparent;
    padding: 0 0 0 0px;
}

.the-chats .chat-user {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background: #fff;
    padding: 15px 15px 5px;
    box-shadow: -2px 2px 3px #ccc;
    margin-bottom: 20px;
}

.the-chats .my-chat {
    height: 520px;
    overflow-y: scroll;
}

.the-chats .my-chat::-webkit-scrollbar {
    width: 0;
}

.my-chat-inner {
    height: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
}

.send-message {
    text-align: right;
    margin: 12px 0 0 auto;
    display: table;
}

.message-body {
    background-color: #fff;
    font-family: 'Poppins';
    padding: 10px 19px;
    text-align: left;
    border-radius: 50px;
    border: 1px solid #eee;
}

.message-body span {
    color: #202020;
    font-size: 12px;
}

.send-on {
    text-align: left;
    clear: both;
    margin-top: 6px;
}

.send-on span {
    color: #666;
    font-size: 10px;
    opacity: 0.5;
    font-family: "Poppins";
}

.recive-message {
    margin-top: 30px;
    display: flex;
}

.recive-message .recieve-from-img {
    margin-bottom: 10px;
}

.my-chat-inner .recive-message .recieve-from-img {
    margin-right: 12px;
}

.message-recieved {
    margin: 0 auto 8px 0;
    display: table;
}

.that-message {
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    border: 1px solid #cbcbcb;
}

.that-message span {
    color: #202020;
    font-family: "poppins";
    font-size: 12px;
}

.recieved-on {
    text-align: right;
}

.recieved-on span {
    color: #666;
    font-size: 10px;
    opacity: 0.5;
}

.send-new-message {
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    left: 0;
    padding: 0 30px 0 25px;
}

.for-new-message {
    width: 98%;
    margin-right: 2%;
}

.for-new-message input {
    background: #fff;
    box-shadow: none;
    color: #000;
    height: 57px;
    outline: none;
    padding: 0 20px;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #eee;
}

.for-new-message input::placeholder {
    color: #000;
    font-family: "Poppins";
    font-size: 14px;
    /* padding-left: 10px; */
    opacity: 0.5;
}

.for-send-msg-btn button {
    background: linear-gradient(to right,
            #00a546c7,
            #17723e,
            #18723f,
            #17703e,
            #08ad4f);
    border: 0px;
    width: 125px;
    height: 50px;
    color: #ffffff;
    font-family: "Poppins";
    font-size: 16px;
    border-radius: 50px;
}

@media (max-width: 575px) {
    .recive-message {
        display: block;
    }

    .that-message {
        border-radius: 5px;
    }

    .message-body {
        border-radius: 5px;
    }

    .send-new-message {
        display: block;
        padding: 0 10px;
        background: #fff;
        position: unset;
        padding-top: 10px;
    }

    .send-new-message .for-new-message {
        width: 100%;
        margin: 0;
    }

    .send-new-message input {
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .for-send-msg-btn button {
        border-radius: 5px;
        width: 100%;
    }

    .chat_sec .chat-list {
        border: 1px solid #ddd;
        margin: 0 10px 20px;
    }

    .the-chats {
        margin: 0 10px;
    }

    .the-chats .chat-user {
        box-shadow: 0px 2px 3px #ccc;
    }
}

/* Chat Css End Here */