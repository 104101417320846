/* Block Css Start Here */
.block-list {
    padding: 180px 0 60px;
}

.block-list .follow-box {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    justify-content: space-between;
}

.block-list .follow-box .left-side {
    display: flex;
    align-items: center;
}

.block-list .follow-box figure {
    margin: 0;
}

.block-list .follow-box .name {
    padding-left: 20px;
}

.block-list .follow-box .name p {
    font-size: 14px;
    line-height: 14px;
    color: #6c6c6c;
    margin-bottom: 4px;
}

.block-list .follow-box .name span {
    font-size: 12px;
    line-height: 10px;
    color: #a9a9a9;
}

.block-list .follow-box .name h5 {
    font-size: 18px;
}

.block-list .follow-box figure img {
    max-width: 60px;
}

.block-list .follow-box .left-side {
    display: flex;
    align-items: flex-start;
}

.block-list .follow-box .buton-group .btn {
    background: #fb6e6e;
    padding: 8px 40px;
    color: #fff;
    border-radius: 6px;
    transition: 0.6s;
}

.block-list .follow-box .buton-group .btn:hover {
    background: transparent;
    box-shadow: 0px 0px 0px 2px #414141;
}

.block-list .block-list-box {
    background: #ffffff12;
    padding: 40px 100px;
    border-radius: 10px;
}

.block-list .block-list-box {
    background: #85858512;
    padding: 40px 100px;
    border-radius: 10px;
}

.block-list .block-list-box .heading {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
}

/* Block Css End Here */