/* Profile Css Start Here */
.profile_sec {
    padding: 60px 0;
}

.profile_sec {
    padding: 60px 0;
}

.profile_sec .profile_sidebar {
    border: 1px solid #ddd;
    padding: 30px 0 10px;
    background: #49a8c90d;
}

.profile_sec .profile_sidebar .profile-header {
    padding: 0px 20px;
    text-align: center;
}

.profile_sec .profile_sidebar .profile-img {
    max-width: 120px;
    margin: 0px auto;
}

.profile_sec .profile_sidebar .name {
    padding: 12px 0;
}

.profile_sec .profile_sidebar .name h4 {
    font-size: 25px;
    font-weight: 500;
}

.profile_sec .profile_sidebar .name p {
    font-size: 15px;
    line-height: 20px;
}

.profile_sec .profile_sidebar ul.menus {
    padding: 0;
    margin: 0;
    list-style: none;
}

.profile_sec .profile_sidebar ul.menus li {
    border-top: 1px solid #ddd;
    padding: 8px 13px;
}

.profile_sec .profile_sidebar ul.menus li a {
    color: #000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    text-decoration: none;
}

.right_sidebar .recent-box {
    border: 1px solid #ddd;
    padding: 10px 0 10px;
    background: #49a8c90d;
    margin-top: 20px;
}

.right_sidebar .recent-box .title {
    border-bottom: 1px solid #ddd;
    padding: 5px 15px;
}

.right_sidebar .recent-box .title h4 {
    font-size: 20px;
    line-height: 29px;
    font-weight: 500;
}

.right_sidebar .recent-box ul.list {
    padding: 0;
    margin: 0;
    height: 190px;
    overflow-y: scroll;
}

.right_sidebar .recent-box ul.list li.menu-items {
    list-style: none;
}

.right_sidebar .recent-box ul.list li.menu-items a {
    text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
}

.right_sidebar .recent-box ul.list li.menu-items a p {
    margin: 0;
    font-size: 12px;
}

.right_sidebar .recent-box ul.list li.menu-items figure img {
    width: 40px;
    border-radius: 50%;
    height: 37px;
}

.right_sidebar .recent-box ul.list li.menu-items .description {
    width: 90%;
    margin-left: 10px;
}

.right_sidebar .recent-box ul.list {
    padding: 0;
    margin: 0;
    height: 280px;
    overflow-y: scroll;
}

.right_sidebar .recent-box ul.list::-webkit-scrollbar {
    width: 0em;
}

.right_sidebar .your-timline-box {
    border: 1px solid #ddd;
    padding: 10px 0 6px;
    background: #49a8c90d;
}

.right_sidebar .your-timline-box .title {
    padding: 0 18px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.right_sidebar .your-timline-box .title h4 {
    font-size: 20px;
    line-height: 29px;
    font-weight: 500;
}

.right_sidebar .your-timline-box ul.timeline-years {
    padding: 0;
    margin: 0;
    list-style: none;
    height: 130px;
    overflow-y: scroll;
}

.right_sidebar .your-timline-box ul.timeline-years::-webkit-scrollbar {
    width: 0em;
}

.right_sidebar .your-timline-box ul.timeline-years li {
    display: inline-block;
    text-align: center;
    margin: 0 8px;
}

.right_sidebar .your-timline-box ul.timeline-years li .box {
    background: #17723e;
    margin: 0 auto 5px;
    color: #fff;
    font-size: 14px;
    padding: 7px 17px;
    display: inline-block;
    border-radius: 5px;
    position: relative;
    width: 65px;
}

.right_sidebar .your-timline-box ul.timeline-years li .box span.count {
    font-size: 10px;
    background: #e0c447;
    color: #000;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    position: absolute;
    right: -8px;
    top: 0;
}

.profile_sec .profile_sidebar .profile-img img {
    width: 120px;
    border-radius: 50%;
}

.status-card-wrapper {
    background: #fff;
    padding: 25px 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #ccc;
}

.status-card-wrapper .status-field-and-profile-wrapper {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.status-card-wrapper .status-field-and-profile-wrapper .status-field-wrapper {
    flex: 1;
    display: flex;
    position: relative;
}

.status-card-wrapper .status-list-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.status-card-wrapper .status-list-wrapper button.btn {
    display: flex;
    align-items: center;
}

.status-card-wrapper .status-list-wrapper button.btn img.img-fluid {
    margin-top: -9px;
}

.status-card-wrapper input.form-control {
    border-radius: 50px;
    background: transparent;
    border: 1px solid #ddd;
    height: 50px;
    font-size: 14px;
    padding: 0 20px;
    margin: 0 0px 0 10px;
}

.status-card-wrapper .status-profile-wrapper img {
    width: 48px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #000;
    border-radius: 50px;
}

.status-card-wrapper button.add-stuff {
    border: 0;
    box-shadow: none;
    outline: none;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    position: absolute;
    right: 5px;
    top: 5px;
    color: #fff;
    background: #17723e;
}

.status-card-wrapper button.add-stuff svg {
    width: 25px;
    height: 25px;
}

@media (max-width: 575px) {
    .right_sidebar .recent-box ul.list {
        height: auto;
    }

    .right_sidebar .your-timline-box ul.timeline-years {
        height: auto;
    }
}

/* First Tab Css Start Here */
.profile_sec .profile_sidebar .profile-img img {
    width: 120px;
    border-radius: 50%;
}

.status-card-wrapper {
    background: #fff;
    padding: 25px 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #ccc;
}

.all-posts {
    position: relative;
}

.all-posts .weeks .title {
    padding: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.all-posts .weeks .title h3 {
    margin: 0;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 400;
    flex: 1;
}

.all-posts .filter {
    width: 40%;
    position: absolute;
    top: 20px;
    right: 0;
}

.all-posts .filter .form-group {
    margin: 0;
    display: flex;
    align-items: center;
}

.all-posts .filter .form-group select {
    background: transparent;
    border-radius: 0;
    font-size: 14px;
    box-shadow: none;
    border-color: #ddd !important;
}

.status-card-wrapper .status-field-and-profile-wrapper {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.all-posts .weeks .title select.form-control {
    flex: 1;
    border-radius: 0;
    width: 100px;
    display: block;
    margin: 0px 0 0 auto;
}

.live-btn-wrapper {
    width: 48%;
    text-align: center;
}

.live-btn-wrapper.buttons {
    display: flex;
    /* flex: 1; */
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.live-btn-wrapper.buttons > button {
    display: flex;
    flex: 1 !important;
}

.live-btn-wrapper.buttons > label {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.status-card-wrapper .status-list-wrapper .uploaded-file {
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.status-card-wrapper .status-list-wrapper .uploaded-file img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.status-card-wrapper .status-field-and-profile-wrapper .status-field-wrapper {
    flex: 1;
    display: flex;
    position: relative;
}

.status-card-wrapper .status-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.status-card-wrapper .status-list-wrapper button.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    box-shadow: none;
}

.status-card-wrapper .status-list-wrapper button.btn img.img-fluid {
    margin-top: -9px;
}

.status-card-wrapper .form-control {
    border-radius: 50px;
    background: transparent;
    border: 1px solid #ddd;
    height: 50px;
    font-size: 14px;
    padding: 14px 60px 14px 20px;
    margin: 0 0px 0 10px;
}

.status-card-wrapper .status-profile-wrapper img {
    width: 48px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #000;
    border-radius: 50px;
    height: 48px;
}

.status-card-wrapper button.add-stuff {
    border: 0;
    box-shadow: none;
    outline: none;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    position: absolute;
    right: 5px;
    top: 5px;
    color: #fff;
    background: #17723e;
}

.status-card-wrapper button.add-stuff svg {
    width: 25px;
    height: 25px;
}

.status-card-wrapper .status-list-wrapper .live-btn-wrapper span {
    margin-left: 5px;
}

.post-feed-wrapper .feed-profile-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
}

.post-feed-wrapper .feed-profile-wrapper .user {
    display: flex;
    align-items: center;
}

.post-feed-wrapper .feed-profile-wrapper .more-option-wrapper i {
    color: #000;
}

.post-feed-wrapper .feed-profile-wrapper .more-option-wrapper span {
    color: #000;
    font-size: 13px;
    margin-right: 5px;
    font-family: "Rubik";
}

.post-feed-wrapper .feed-profile-wrapper .more-option-wrapper button {
    display: flex;
    align-items: center;
    box-shadow: none !important;
}

.post-feed-wrapper .feed-profile-wrapper .feed-name-wrapper {
    margin-left: 10px;
}

.post-feed-wrapper .feed-profile-wrapper .feed-name-wrapper h4 {
    margin: 0;
}

.post-feed-wrapper .feed-profile-wrapper .feed-name-wrapper h4 a {
    color: #000;
    font-weight: 500;
    text-decoration: none;
}

.post-feed-wrapper .feed-des-wrapper {
    margin: 20px 0 20px;
}

.post-feed-wrapper .feed-profile-wrapper .feed-img-wrapper img {
    width: 60px;
    border-radius: 50%;
}

.post-feed-wrapper .feed-des-wrapper button.hash-tag {
    color: #17723e;
    background: transparent;
    border: 0px;
    font-size: 15px;
    padding: 0px;
}

.hash-tag {
    color: #17723e;
    background: transparent;
    border: 0px;
    font-size: 15px;
    padding: 0px;
    display: inline;
}

.post-feed-wrapper .likes-comment-share-wrapper {
    padding-bottom: 26px;
    /* border-bottom: 1px solid #484848; */
}

.post-feed-wrapper .likes-comment-share-wrapper {
    display: flex;
}

.post-feed-wrapper .shares-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.post-feed-wrapper .shares-options ul.pics {
    padding: 0;
    list-style: none;
    display: flex;
}

.post-feed-wrapper .shares-options ul.pics li {
    margin-left: -13px;
}

.post-feed-wrapper .shares-options ul.pics li img {
    width: 35px;
    height: 35px;
}

.post-feed-wrapper .likes-comment-share-wrapper button {
    margin-right: 18px;
    background: transparent;
    border: 0px;
    display: flex;
    align-items: center;
}

.post-feed-wrapper .likes-comment-share-wrapper button span {
    color: #000;
    font-family: "Poppins";
    font-size: 18px;
    margin-left: 9px;
}

.post-feed-wrapper .comments-wrapper .single-comment-wrapper {
    display: flex;
}

.post-feed-wrapper
    .comments-wrapper
    .single-comment-wrapper
    .comment-content-wrapper {
    margin-left: 18px;
}

.post-feed-wrapper .comments-wrapper .single-comment-wrapper img.comment-img {
    height: 41px;
    width: 41px;
    object-fit: cover;
    border-radius: 50%;
}

.post-feed-wrapper
    .comments-wrapper
    .single-comment-wrapper
    .comment-content-wrapper
    h6.profile-name
    a {
    font-size: 16px;
    color: #000;
    opacity: 0.9;
    text-decoration: none;
    font-weight: 600;
}

.post-feed-wrapper
    .comments-wrapper
    .single-comment-wrapper
    .comment-content-wrapper
    p {
    margin-bottom: 0px;
    font-size: 16px;
}

.post-feed-wrapper .comments-wrapper .single-comment-wrapper {
    display: flex;
    padding: 20px 0px 0px 0px;
}

.post-feed-wrapper .post-comment-wrapper {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    padding: 10px 0px;
    margin-top: 28px;
}

.post-feed-wrapper .post-comment-wrapper .post-content-wrapper {
    flex: 1;
}

.post-feed-wrapper .post-comment-wrapper .react-input-emoji--container {
    margin: 0;
}

.post-feed-wrapper .post-comment-wrapper .post-btn-wrapper {
    flex: 0 0 13%;
}

.post-feed-wrapper .post-comment-wrapper .emoji-wrapper {
    flex: 0 0 7%;
}

.post-feed-wrapper .post-comment-wrapper .emoji-wrapper figure {
    margin: 0px;
}

.post-feed-wrapper .post-comment-wrapper .post-content-wrapper input {
    background: transparent;
    border: 0px;
    color: #000;
    box-shadow: none;
    font-family: "Poppins";
}

.post-comment-wrapper .post-content-wrapper input::placeholder {
    color: #000;
    font-family: "Poppins";
    font-size: 14px;
}

.post-feed-wrapper .post-comment-wrapper .post-btn-wrapper button.btn {
    background: #17723e;
    border-radius: 9px;
    padding: 8px 17px;
    color: #fff;
    font-family: "Rubik";
    font-size: 15px;
}

.post-feed-wrapper .more-option-wrapper .btn,
.post-feed-wrapper .more-option-wrapper .btn:focus,
.post-feed-wrapper .more-option-wrapper .btn:active {
    background: transparent;
    border: 0px;
    box-shadow: none;
}

.post-feed-wrapper .more-option-wrapper .btn::after {
    display: none;
}

.post-feed-wrapper .more-option-wrapper .dropdown-menu {
    background: #fff;
    border: 0px;
    box-shadow: 0px 0px 10px #ddd;
}

.post-feed-wrapper .more-option-wrapper .dropdown-menu a {
    color: #000;
    font-size: 14px;
}

.post-feed-wrapper .more-option-wrapper .dropdown-menu a:hover {
    background: #e9ecef !important;
}

.post-feed-wrapper {
    background: #fff;
    box-shadow: 0px 0px 5px #ccc;
    padding: 15px 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.post-feed-wrapper .comments-wrapper {
    margin-top: 2px;
    display: flex;
    flex-direction: column-reverse;
}

.post-feed-wrapper .comments-wrapper .load-more {
    margin-top: 20px;
}

.post-feed-wrapper .comments-wrapper .load-more a {
    color: #17723e;
    font-size: 14px;
    font-weight: 500;
}

.post-feed-wrapper .comments-wrapper::-webkit-scrollbar {
    width: 0em;
}

.profile_sec ul.pagination {
    margin: 25px 0 0 0;
    padding: 0;
    text-align: center;
    justify-content: center;
}

.profile_sec ul.pagination li a {
    background: #17713d;
    min-width: 36px;
    display: block;
    height: 40px;
    line-height: 36px;
    border-radius: 4px;
    color: #fff;
    transition: 0.6s;
    border: 2px solid #17713d;
    text-decoration: none;
    margin: 0 1px;
    padding-left: 10px;
    padding-right: 10px;
}

.profile_sec ul.pagination li button {
    background: #17713d;
    min-width: 36px;
    display: block;
    height: 40px;
    line-height: 36px;
    border-radius: 4px;
    color: #fff;
    transition: 0.6s;
    border: 2px solid #17713d;
    text-decoration: none;
    margin: 0 1px;
    padding-left: 10px;
    padding-right: 10px;
}

.profile_sec ul.pagination li a:hover {
    background: transparent;
    color: #17713d;
}

.profile_sec ul.pagination li button:hover {
    background: transparent;
    color: #17713d;
}

@media (max-width: 575px) {
    .status-card-wrapper {
        margin-top: 20px;
    }

    .right_sidebar .recent-box {
        margin-top: 20px;
    }

    .right_sidebar .your-timline-box ul.timeline-years li .box {
        width: 80px;
    }

    .post-feed-wrapper .more-option-wrapper .dropdown-menu {
        right: unset !important;
        left: -50px !important;
    }

    .all-posts .weeks .title {
        padding: 10px 0 0;
    }

    .react-emoji-picker--wrapper {
        left: 0 !important;
        right: 0 !important;
        margin: 0px auto !important;
        width: 100% !important;
        overflow-x: scroll !important;
    }

    .all-posts .filter {
        position: unset;
        margin-top: 17px;
        width: 100%;
    }

    .all-posts .filter .form-group select {
        height: 45px;
    }

    .post-feed-wrapper .feed-profile-wrapper .feed-img-wrapper img {
        width: 40px;
    }

    .post-feed-wrapper .feed-profile-wrapper .feed-name-wrapper h4 {
        margin: 0;
        font-size: 14px;
    }

    .post-feed-wrapper .feed-des-wrapper button.hash-tag {
        font-size: 12px;
    }

    .post-feed-wrapper .feed-des-wrapper p {
        font-size: 13px;
    }

    .post-feed-wrapper .post-comment-wrapper {
        display: block;
    }

    .post-feed-wrapper .post-comment-wrapper .post-btn-wrapper button.btn {
        width: 100%;
        margin-top: 10px;
        border-radius: 50px;
    }

    .post-feed-wrapper
        .comments-wrapper
        .single-comment-wrapper
        .comment-content-wrapper {
        margin-left: 10px;
    }

    .profile_sec ul.pagination {
        margin: 25px 0 10px 0;
    }
}

/* First Tab Css End Here */

/* Third Tab Css Start Here */
.favourite_quote_content .quote_box {
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #17723e1f;
    box-shadow: 0px 0px 10px #ddd;
    padding: 35px 5px 30px;
    position: relative;
}

.favourite_quote_content .quote_box .quote {
    border: 0;
    height: 100px;
    font-size: 15px;
    overflow-y: scroll;
    background: transparent;
    box-shadow: none;
    outline: none;
    font-weight: 400;
    font-style: italic;
    font-family: "Rubik";
}

.favourite_quote_content .quote_box .quote::-webkit-scrollbar {
    width: 0em;
}

.favourite_quote_content .quote_box button.copybtn {
    border: 0;
    box-shadow: none;
    outline: none;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
}

@media (max-width: 575px) {
    .favourite_quote_content {
        margin: 20px 0;
    }
}

/* Third Tab Css End Here */

/* Fourth Tab Css Start Here */
.hobbies_content thead {
    background: #edfff8;
}

.hobbies_content thead th {
    border: 0;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
}

.medicalModal .form-group {
    margin-bottom: 0;
}

.medicalModal .form-group input[type="file"] {
    border: 0;
    padding: 0;
    margin: 20px 0 0;
    box-shadow: none;
}

.hobbies_content tr td {
    border: 0;
}

.hobbies_content thead th:first-child {
    text-align: left;
}

.hobbies_content tbody tr {
    border-bottom: 1px solid #ddd;
}

.hobbies_content tbody tr td {
    font-size: 14px;
    color: #000;
    padding: 14px 0;
    text-align: center;
    vertical-align: middle;
}

.hobbies_content tbody tr td:first-child {
    text-align: left;
}

.hobbies_content tbody tr td.edit {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hobbies_content tbody tr td button {
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    outline: none;
    background: transparent;
}

.hobbies_content tbody tr td.edit button.editBtn {
    background: #17723e;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
}

.hobbies_content tbody tr td.edit button.deleteBtn {
    background: red;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    margin: 0 6px;
}

@media (max-width: 575px) {
    .hobbies_content .title {
        margin: 20px 0 0;
    }

    .hobbies_content .title h3 {
        font-size: 25px;
        line-height: 35px;
    }

    .hobbies_content .table-resposnive {
        overflow-x: scroll;
    }

    .hobbies_content .table-resposnive td {
        font-size: 12px;
        min-width: 100px !important;
        padding: 10px 10px;
    }
}

/* Fourth Tab Css End Here */
.profile_sec .profile_sidebar ul.menus li .card {
    padding: 0;
    background: transparent;
    border: 0;
}

.profile_sec .profile_sidebar ul.menus li .card .card-header {
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
}

.profile_sec .profile_sidebar ul.menus li .card button,
li .card button h5 {
    background: transparent;
    box-shadow: none;
    outline: none;
    padding: 0;
    color: #000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
}

.profile_sec .profile_sidebar ul.menus li .card .card-body {
    padding: 8px 20px;
    font-size: 15px;
    font-family: "Rubik";
    font-weight: 400;
}

@media (max-width: 575px) {
    .status-card-wrapper .status-field-and-profile-wrapper {
        display: block;
        text-align: center;
    }

    .status-card-wrapper
        .status-field-and-profile-wrapper
        .status-field-wrapper {
        display: block;
        margin-top: 15px;
    }

    .status-card-wrapper input.form-control {
        font-size: 14px;
        padding: 0 20px 0 20px;
        margin: 0 0px 0 0px;
    }

    .status-card-wrapper button.add-stuff {
        position: unset;
        width: 100%;
        margin-top: 10px;
    }
}

/* Profile Css End Here */

/* Emoji Css Start Here */
.post-feed-wrapper .like-wrapper {
    position: relative;
}

.post-feed-wrapper .like-wrapper ul.reactions {
    padding: 5px 10px;
    margin: 0;
    list-style: none;
    background: #fff;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 50px;
    position: absolute;
    top: -52px;
    z-index: 9;
    left: -10px;
    display: none;
}

.post-feed-wrapper .like-wrapper ul.reactions li span {
    font-size: 30px;
}

.post-feed-wrapper .like-wrapper button {
    background: transparent;
    border: 0;
    padding: 0;
    box-shadow: none;
    outline: none;
    text-align: left;
}

.post-feed-wrapper .like-wrapper ul.reactions li {
    margin: 0 3px;
}

.post-feed-wrapper .like-wrapper button span {
    font-size: 16px;
    padding-right: 15px;
    color: #17723e;
    font-weight: 500;
}

.post-feed-wrapper .like-wrapper:hover ul.reactions {
    display: flex;
}

.post-feed-wrapper .like-wrapper span.setEmoji {
    font-size: 30px;
    padding: 0px 10px 0 0px;
}

.profile_sec .top-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px;
}

.family_contribution_content {
    cursor: pointer;
}

.profile_sec .top-buttons .left-buttons {
    display: flex;
    align-items: center;
}

.profile_sec .top-buttons .left-buttons .back-to-home i {
    margin-right: 5px;
}

.profile_sec .top-buttons .left-buttons .back-to-home {
    background: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
    padding: 0 20px 0 0px;
    font-family: "Poppins";
    font-size: 14px;
    color: #6c6c6c;
}

/* Emoji Css End Here */

.uploaded-file > i {
    right: -10px;
    top: -10px;
    border-radius: 100%;
    background-color: #17723e;
}
.br-12 {
    border-radius: 12px;
}
.br-8 {
    border-radius: 8px;
}
.btn-success {
    background-color: #17723e !important;
}
