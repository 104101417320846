/* Saved Payment Css Start Here */
.payment-details {
    padding: 170px 0 60px;
}

.payment-details .heading h3 {
    color: #000;
}

.payment-details .saved-payment-method h5 {
    padding-bottom: 10px;
    font-size: 14px;
    color: #000000e3;
}

.payment-details .saved-payment-method label.container {
    padding: 0;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #707070;
    padding: 10px 20px;
    position: relative;
    border-radius: 10px;
}

.payment-details
    .saved-payment-method
    label.container
    input:checked
    ~ span.checkmark
    .card-details {
    border-color: #217846;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .visa-card {
    display: flex;
    align-items: center;
    padding-left: 40px;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .visa-card
    figure {
    margin-bottom: 0;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .visa-card
    .input-box
    label {
    color: #000000;
    font-size: 8px;
    font-weight: 600;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .visa-card
    span {
    height: unset;
    font-size: 18px;
    font-weight: 600;
    background: transparent;
    border: 0;
    color: #000;
    outline: none;
    box-shadow: none;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .extra-btn {
    display: flex;
    align-items: center;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .extra-btn
    button.btn {
    margin-right: 4px;
    background: #c9c9c945;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 1;
    border-radius: 0;
    padding: 6px 20px;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .visa-card
    .input-box {
    margin-left: 15px;
    margin-bottom: 3px;
}

.payment-details
    .saved-payment-method
    label.container.second-card
    button.btn.default.disabled {
    visibility: hidden;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .extra-btn
    .selection {
    opacity: 0;
}

.payment-details
    .saved-payment-method
    label.container
    input:checked
    ~ span.checkmark
    .card-details
    .extra-btn
    .selection {
    opacity: 1;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .extra-btn
    .selection
    i {
    background: #0eb702;
    color: #fff;
    font-size: 14px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
}

.payment-details .saved-payment-method label.container input[type="radio"] {
    opacity: 0;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .radio-btn
    span {
    background: transparent;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 15px;
    border-radius: 50%;
    border: 2px solid #000;
    box-shadow: 0px 0px 0px 1px #217846;
    top: 27.5px;
}

.payment-details
    .saved-payment-method
    label.container
    input:checked
    ~ span.checkmark
    .card-details
    .radio-btn
    span {
    background: #217846;
}

.payment-details
    .saved-payment-method
    label.container
    span.checkmark
    .card-details
    .radio-btn
    span {
    background: transparent;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #217846;
    top: 27.5px;
}

.payment-details
    .saved-payment-method
    .add-new-method
    label.container
    span.checkmark {
    border: 2px solid #fff;
    width: 15px;
    height: 15px;
    background: transparent;
    position: absolute;
    left: 0;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px #217846;
    top: 16px;
}

.payment-details .saved-payment-method .add-new-method {
    position: relative;
}

.payment-details .saved-payment-method .add-new-method label.container {
    padding-left: 30px;
    font-size: 15px;
    color: #626262c7;
    font-weight: 500;
    padding-bottom: 15px;
}

.payment-details .saved-payment-method .add-new-method {
    padding-top: 10px;
}

.payment-details
    .saved-payment-method
    .add-new-method
    label.container
    input:checked
    ~ span.checkmark {
    background: #217846;
}

.payment-details
    .saved-payment-method
    .add-new-method
    .form-group
    .form-control {
    height: 55px;
    border-color: #a4a4a4;
    border-radius: 10px;
    background: transparent;
    color: #000;
    font-weight: 400;
}

.payment-details
    .saved-payment-method
    .add-new-method
    .form-group
    .form-control::placeholder {
    color: #626262c7 !important;
}

.payment-details
    .saved-payment-method
    .add-new-method
    .form-group
    .form-control::placeholder {
    color: #55555578;
    font-weight: 400;
    font-size: 15px;
}

.payment-details .saved-payment-method .add-new-method button {
    width: 100%;
    padding: 13px 0;
    cursor: pointer;
    background: #217846;
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 500;
    transition: 0.6s;
    font-family: "Poppins";
    border-radius: 6px;
}

.payment-details .saved-payment-method .add-new-method button:hover {
    box-shadow: 0px 0px 0px 2px #217846;
    background: transparent;
    color: #217846;
}

.payment-details .img-box > figure > img {
    width: 80px;
    height: 50px;
}

/* Saved Payment Css Start Here */
