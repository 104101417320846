/* About Css Start Here */
.universal_sec {
    padding: 60px 0;
}

.universal_sec h2.title {
    padding-bottom: 10px;
    text-transform: capitalize;
    font-size: 34px;
}

.universal_sec p {
    font-size: 14px;
    line-height: 22px;
    color: #626262;
}

.universal_sec p a {
    text-decoration: none;
}

@media (max-width: 575px) {
    .universal_sec {
        padding: 40px 0 0;
    }

    .planning_sec {
        padding-bottom: 40px;
    }

    .universal_sec p {
        text-align: justify;
    }

    .universal_sec h2.title {
        font-size: 26px;
        line-height: 36px;
    }

    .long_term .row {
        flex-direction: column-reverse;
    }

    .planning_sec .row {
        flex-direction: column-reverse;
    }

    .universal_sec .img-box {
        margin-bottom: 20px;
    }

}

/* About Css End Here */