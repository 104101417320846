/* Contact Css Start Here */
.contact_sec {
    padding: 80px 0;
}

.contact_sec .contact_img {
    filter: brightness(0.8);
}

.contact_sec .heading {
    padding-bottom: 20px;
}

.contact_sec .form-control {
    border-radius: 0;
    height: 60px;
    padding: 5px 15px;
}

.contact_sec textarea {
    height: 190px !important;
    resize: none;
}

.contact_sec .button-group .btn {
    background: #17723e;
    border-radius: 0;
    padding: 15px 0;
    width: 100%;
    margin-top: 10px;
    color: #fff;
    border: 2px solid #fff;
    transition: 0.6s;
}

.contact_sec .button-group .btn:hover {
    box-shadow: 0px 0px 0px 2px #17723e;
}

.contact_sec .contact_img img {
    height: 650px;
    object-fit: cover;
    object-position: left;
}

/* Contact Css End Here */