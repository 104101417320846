/* Pricing Css Start Here */
.pricing_sec {
    padding: 50px 0;
}

.pricing_sec .heading-bar {
    text-align: center;
}

.pricing_sec .heading-bar h3.title {
    padding-bottom: 5px;
    font-size: 40px;
    line-height: 50px;
    color: #000;
}

.pricing_sec .content-wrapper .heading-bar h3.title {
    font-size: 30px;
    line-height: 40px;
}

.pricing_sec .heading-bar p {
    font-size: 14px;
}

.pricing_sec .package-box p b {
    color: #17723e;
}

.pricing_sec .nav-tabs {
    border: 0;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.pricing_sec .nav-tabs .nav-item {
    border: 0;
    background: #17723e80;
    border-radius: 0;
    color: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    margin: 0 10px;
}

.pricing_sec .nav-tabs .nav-item.active {
    background: #17723e;
}

.pricing_sec .package-box {
    border: 1px solid #ddd;
    padding: 15px 15px;
    border-radius: 10px;
}

.pricing_sec .package-box {
    background: #17723e33;
    color: #000;
    margin-top: 30px;
}

.pricing_sec .package-box .package-title {
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
}

.pricing_sec .package-box .package-title h4 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    font-family: 'Rubik';
}

.pricing_sec .package-box i {
    font-size: 14px;
}

.pricing_sec .package-box p {
    font-size: 14px;
    padding: 15px 0;
    margin: 0;
    border-bottom: 1px solid #ddd;
    min-height: 136px;
}

.pricing_sec .package-box p b {
    color: #17723e;
}

.pricing_sec .package-box ul.including {
    margin: 0;
    padding: 15px 0;
    list-style: none;
}

.pricing_sec .package-box .button-group button {
    background: #17723e;
    padding: 9px 20px;
    border-radius: 50px;
    color: #000;
    text-decoration: none;
    display: block;
    text-align: center;
    border: 2px solid #d1e3d8;
    transition: 0.6s;
    color: #fff;
}

.pricing_sec .package-box .button-group button.disabled {
    background: #17723e80;
    padding: 9px 20px;
    border-radius: 50px;
    color: #000;
    text-decoration: none;
    display: block;
    text-align: center;
    border: 2px solid #d1e3d8;
    transition: 0.6s;
    color: #fff;
}


.pricing_sec .package-box .button-group a {
    background: #17723e;
    padding: 9px 20px;
    border-radius: 50px;
    color: #000;
    text-decoration: none;
    display: block;
    text-align: center;
    border: 2px solid #d1e3d8;
    transition: 0.6s;
    color: #fff;
}

.pricing_sec .package-box ul.including li {
    font-size: 14px;
    margin: 5px 0px;
}

.pricing_sec .package-box .button-group button:hover {
    color: #17723e;
    background: transparent;
    border-color: #17723e;
}
.pricing_sec .package-box .button-group a:hover {
    color: #17723e;
    background: transparent;
    border-color: #17723e;
}

@media (max-width: 575px) {
    .pricing_sec .heading-bar {
        text-align: left;
    }

    .pricing_sec .heading-bar h3.title {
        font-size: 30px;
        line-height: 40px;
    }

    .login_sec .remeberdv {
        display: block;
    }

    .login_sec .remeberdv .link {
        text-align: left;
        margin-top: 8px;
    }

    .login_sec {
        padding: 40px 0;
    }
}

/* Pricing Css End Here */