

.male_node {
    background-color: lightblue;
    width:250px;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.female_node {
    background-color: lightpink;
    width:250px;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}