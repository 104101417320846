.gradient-btn {
    background-image: linear-gradient(to right, #00a546c7, #17723e, #18723f, #17703e, #08ad4f);
    font-size: 14px;
    color: #ffffff;
    padding: 13px 50px;
    /* width: 100%; */
    border-radius: 4px;
    /* margin-top: 32px; */
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.6s;

}