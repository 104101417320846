/* New Homepage Css Start Here */
.blue-txt {
    color: #47a8ca;
}
.new_banner_sec {
    padding: 150px 0 80px;
    position: relative;
    height: 1000px;
    overflow: hidden;
}
.new_banner_sec .banner-content {
    z-index: 1;
}
.new_banner_sec .banner-video {
    position: absolute;
    top: 0;
}
.new_banner_sec .familytree_form {
    padding: 30px 15px;
    border-radius: 10px;
    background-image: linear-gradient(
        to right top,
        #49c949,
        #00c38b,
        #00b8bc,
        #00a8d4,
        #0994d1
    );
}
.new_banner_sec .familytree_form .heading h4 {
    font-size: 23px;
    line-height: 30px;
    color: #fff;
}
.new_banner_sec .familytree_form .form-control {
    height: 45px;
    font-size: 13px;
    background: #ffffff8f;
    color: #000;
    border: 0;
}
.new_banner_sec .familytree_form .form-control::placeholder {
    color: #000;
    opacity: 1;
}
.new_banner_sec .familytree_form .input-group input {
    width: 20px;
    border: 0;
    box-shadow: none;
}
.new_banner_sec .familytree_form .btn {
    background: #fff;
    width: 100%;
    margin-top: 10px;
    padding: 11px 0;
    font-size: 15px;
    font-family: "Rubik";
}
/* New Homepage Css End Here */

/* Second Sec Css Start Here */
.start_you {
    background: #47a8ca;
}
.start_you .card-body-content .content-box .title {
    font-weight: 400;
}
.start_you .card-body-content {
    background: #fff;
    padding: 30px 60px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px #ddd;
    margin-top: -5rem;
}
.start_you .card-body-content .content-box {
    text-align: center;
    padding: 20px 0;
}
.start_you .card-body-content .content-box .description {
    color: #000;
    font-weight: 500;
}
.start_you .card-body-content .content-box .seprator {
    background: #47a8ca;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0px auto;
    position: relative;
}
.start_you .card-body-content .content-box hr {
    border-color: #b1b1b1;
    position: absolute;
    bottom: 5px;
    width: 100%;
}
.start_you .card-body-content .content-box .description .tick {
    color: #47a8ca;
    font-size: 24px;
    margin-right: 5px;
}
.start_you .card-body-content .content-box .description .drk-blue {
    color: #1b4392;
}

.familytree_form {
    background: #fff;
    padding: 30px 60px;
    border-radius: 5px;
    margin-top: 3rem;
    box-shadow: 0px 0px 15px #ddd;
    margin-bottom: -6rem;
    position: relative;
}

.familytree_form .form-group .form-control {
    height: 50px;
    border-radius: 3px;
    font-size: 14px;
}
.familytree_form .importBtn {
    color: #47a8ca;
}

.familytree_form .privacy-content label a {
    color: #47a8ca;
}

.familytree_form .privacy-content label {
    font-size: 14px;
}

.familytree_form .input-group input[type="radio"] {
    width: 20px;
    height: 20px;
    box-shadow: none;
}
/* Second Sec Css End Here */

/* Review Sec Css Start Here */
.review_sec {
    background: #1ba682;
    padding: 180px 0 60px;
}

.review_sec .testimonial-box .clients-images li {
    display: inline;
    list-style: none;
    margin: 0 -15px;
}

.review_sec .testimonial-box .clients-images ul {
    margin: 0;
    padding: 0;
}

.review_sec .testimonial-box .clients-images li img {
    width: 75px;
    border-radius: 50%;
    height: 75px;
}

.review_sec .testimonial-box .clients-images li:nth-child(2) {
    z-index: 1;
    position: relative;
}

.review_sec .testimonial-box .clients-images li:nth-child(2) img {
    width: 90px;
    height: 90px;
}

.testimonial-box {
    border: 1px solid #fff;
    max-width: 70%;
    margin: 0 auto;
    padding: 30px 70px 10px;
    text-align: center;
    border-radius: 10px;
}

.testimonial-box .testi-slide {
    color: #fff;
}

.testimonial-box .testi-slide h5 {
    font-size: 18px;
    font-weight: 100;
    line-height: 28px;
    font-style: italic;
    margin-bottom: 20px;
}

.testimonial-box .owl-nav {
    display: none;
}
/* Review Sec Css End Here */
