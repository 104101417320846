.search_results {
    padding: 70px 0;
}

.search_results .profile_box {
    text-align: center;
    margin-bottom: 30px;
    box-shadow: 0px 0px 10px #eee;
    padding: 25px 0;
}

.search_results .profile_box a {
    text-decoration: none;
}

.profile_box .profile-img img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.profile_box .profile-name h4 {
    font-size: 15px;
    font-family: 'Rubik';
    margin: 0;
    line-height: 22px;
    color: #17723e;
}

.profile_box .profile-name p {
    font-size: 15px;
    color: #828282;
    margin: 0;
}

.search_results .profile_box .profile-name {
    margin-top: 10px;
}